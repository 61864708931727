import React, { useState, Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { useSelector } from "react-redux";
import Loading from "./pages/Loading";
import Appointment from "./pages/Appointment";
const Home = lazy(() => import("./pages/Home"));
const User = lazy(() => import("./pages/User"));
const Page404 = lazy(() => import("./pages/Page404"));
const NavBar = lazy(() => import("./components/NavBar/NavBar"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const CutImages = lazy(() => import("./components/Home/Cut-Images/CutImages"));
const PrivateRoutes = lazy(() => import("./components/PrivateRoutes/PrivateRoutes"));
const AuthForm = lazy(() => import("./components/Auth/AuthForm"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

function App() {
  const [imageIsShown, setImageIsShown] = useState(false);
  const isLogged = useSelector((state) => state.auth.isLoggedIn);

  //Replace the following with your app's Firebase project configuration
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    databaseURL: process.env.REACT_APP_USER_DETAILS,
  };
  //initiallize firebase
  // eslint-disable-next-line
  const app = initializeApp(firebaseConfig);
  console.log("app");

  const hideHandler = () => {
    window.scrollTo(0, 50000);
    setImageIsShown(false);
  };

  const showImageHandler = () => {
    setImageIsShown(true);
  };

  return (
    <>
      <Suspense fallback={<Loading />}>
        {imageIsShown && <CutImages onClose={hideHandler} />}
        <NavBar />
        <Routes>
          <Route path="/" element={<Home onShowImages={showImageHandler} />} />
          <Route
            path="/User"
            element={
              <PrivateRoutes>
                <User />
              </PrivateRoutes>
            }
          />
          <Route
            path="/Auth"
            element={!isLogged ? <AuthForm /> : <Navigate replace to="/User" />}
          />
          <Route
            path="/Appointment/:_id"
            element={
              <PrivateRoutes>
                <Appointment />
              </PrivateRoutes>
            }
          />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/*" element={<Navigate replace to="/404" />} />
          <Route path="/404" element={<Page404 />} />
        </Routes>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
