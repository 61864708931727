import React from "react";
import "./loading.css";
import LOGO from "../assets/logo-back.png";
import { SyncLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="loading">
      <img fetchpriority="high" src={LOGO} alt="Barber Logo" />
      <SyncLoader className="load" color={"#ffffff"} loading={true} size={15} />
    </div>
  );
};

export default Loading;
