import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth-slice";
import photosSlice from "./photos-slice";
import servicesSlice from "./services-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    services: servicesSlice.reducer,
    photos: photosSlice.reducer,
  },
});

export default store;
