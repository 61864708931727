import "./user.css";
import Schedule from "../components/Schedule/Schedule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";

function Appointment() {
  const isLogged = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch(authActions);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state;

  const logoutHandler = () => {
    dispatch(authActions.logout());
    navigate("/");
  };

  const backHandler = () => {
    navigate("/User");
  };

  return (
    <section className="user">
      {/* {!isLogged && <AuthForm />} */}
      {isLogged && (
        <div className="c-appointment-user">
          <div className="appointment-user">
            <div className="back">
              <h4 onClick={backHandler}>Back</h4>
            </div>
            <div className="logout">
              <h4 onClick={logoutHandler}>Logout</h4>
            </div>
            <Schedule url={from} />
          </div>
        </div>
      )}
    </section>
  );
}

export default Appointment;
