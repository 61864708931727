import React from "react";
import { InlineWidget } from "react-calendly";

function Schedule({ url }) {
  return (
    <div>
      <InlineWidget url={url} />
    </div>
  );
}

export default Schedule;
