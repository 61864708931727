import { createSlice } from "@reduxjs/toolkit";

const servicesSlice = createSlice({
  name: "services",
  initialState: {
    id: null,
    name: null,
    price: null,
    description: null,
  },
  reducers: {
    setServices(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.price = action.payload.price;
      state.description = action.payload.description;
    },
  },
});

export const servicesActions = servicesSlice.actions;

export default servicesSlice;
