import { createSlice } from "@reduxjs/toolkit";

const photosSlice = createSlice({
  name: "photos",
  initialState: {
    id: null,
    image: null,
  },
  reducers: {
    setImages(state, action) {
      state.id = action.payload.id;
      state.image = action.payload.image;
      //   localStorage.setItem("photosID", action.payload.id);
      //   localStorage.setItem("image", action.payload.image);
    },
  },
});

export const photosActions = photosSlice.actions;

export default photosSlice;
